import { orderBy } from '@progress/kendo-data-query';
import React, { useState, useEffect } from 'react';
import calculateSize from 'calculate-size';
// import './Orchestration.scss';
import styles from './TableUserPreference.module.scss';
import DeleteIcon from '../../../fsa-style/img/trashIcon.svg';
import EditTableIcon from '../../../fsa-style/img/EditTableIcon.svg';
import { Grid, GridColumn as Column, GridNoRecords, GridColumnMenuCheckboxFilter } from '@progress/kendo-react-grid';

const headerStyle = (column) => {
  return (
    <div className={styles.tableHeaderStyle}>
      <div className={styles.tableHeaderTitle} style={{ cursor: 'default' }}>
        {column.title}
      </div>
    </div>
  );
};

export const calculateWidth = (data, field, columnName) => {
  let maxWidth = 0;
  const size = calculateSize(columnName.replaceAll('_', ' '), {
    font: 'Arial',
    fontSize: '26px',
  });
  maxWidth = size.width;
  data.forEach((item) => {
    const contentSize = calculateSize(item[field], {
      font: 'Arial',
      fontSize: '24px',
    });
    if (contentSize.width > maxWidth) {
      maxWidth = contentSize.width;
      if (maxWidth > 500) maxWidth = 500;
    }
  });
  return maxWidth;
};

export default function TableUserPreference(props) {
  const { data = [], fields, total = data.length, page, pageSize, onPageChange } = props;
  const [sort, setSort] = React.useState([]);
  const [tableData, setTableData] = useState([]);

  const onSelectSort = (column) => {
    return (
      <div className={styles.tableHeaderStyle} onClick={() => onSortData(column)}>
        <div className={styles.tableHeaderTitle}>
          <div>{column.title}</div>
          {column.showMenuIcon && <div style={{ marginLeft: '10px' }}>{column.sortType === '' ? '' : column.sortType === 'ASC' ? '⬆' : '⬇'}</div>}
        </div>
      </div>
    );
  };
  useEffect(() => {
    fields.forEach((column) => {
      return (column.sortType = ''), { ...column }; //to remove ASC/DESC arrow
    });
  }, []);

  const onSortData = (column) => {
    fields.forEach((obj) => {
      if (obj.field === column.field) {
        if (obj.sortType === '') {
          obj.sortType = 'ASC';
          props.onSortData(column);
        } else if (obj.sortType === 'ASC') {
          obj.sortType = 'DESC';
          props.onSortData(column);
        } else {
          obj.sortType = '';
          props.onRemoveSort();
        }
      } else {
        obj.sortType = '';
      }
    });
    onSelectSort(column);
  };

  useEffect(() => {
    if (page === 0) {
      const initialData = data.slice(0, pageSize).map((obj, idx) => ({
        ...obj,
        checked: false,
        editIcon: EditTableIcon,
        deleteIcon: DeleteIcon,
      }));
      setTableData(initialData);
    } else {
      const initialData = data.slice(pageSize * page, pageSize + pageSize * page).map((obj, idx) => ({
        ...obj,
        checked: false,
        editIcon: EditTableIcon,
        deleteIcon: DeleteIcon,
      }));
      setTableData(initialData);
    }
  }, [data, page, pageSize]);

  const ColumnMenuCheckboxFilter = (props) => {
    return (
      <div>
        <GridColumnMenuCheckboxFilter {...props} data={data} expanded={true} />
      </div>
    );
  };

  return (
    <>
      <Grid
        {...props}
        total={total}
        data={orderBy(tableData, sort)}
        page={page}
        pageSize={pageSize}
        skip={page * pageSize}
        pageable={{
          buttonCount: 5,
          info: true,
          type: 'numeric',
          pageSizes: [5, 10, 15, 20, 25],
          previousNext: true,
        }}
        onPageChange={onPageChange}
        sortable={true}
        sort={sort}
        onSortChange={(e) => {
          setSort(e.sort);
        }}
      >
        <GridNoRecords>No results</GridNoRecords>

        {data &&
          fields.map((column, index) => (
            <Column
              headerCell={() => onSelectSort(column)}
              field={column.field}
              title={column.title}
              key={index}
              cell={column.cell}
              width={data !== undefined ? calculateWidth(data, column.field, column.title) : column.field}
              // width={160}
              filter={column.filter}
              columnMenu={column.showMenuIcon ? ColumnMenuCheckboxFilter : undefined}
            />
          ))}
        <Column
          headerCell={headerStyle}
          field="edit"
          cell={({ dataItem }) => (
            <td>
              <div className="actionColumn">
                <img src={dataItem.editIcon} style={{ cursor: 'pointer' }} onClick={() => props.editFunc(dataItem)} alt="editIcon"></img>
              </div>
            </td>
          )}
          title="Edit"
          width={100}
          filterable={false}
        />

        {/* <Column
          headerCell={headerStyle}
          field="delete"
          cell={({ dataItem }) => (
            <td>
              <div className="actionColumn">
                <img
                  src={dataItem.deleteIcon}
                  // style={{ cursor: 'pointer' }}
                  // onClick={() => {
                  //   props.DeleteFunc(dataItem);
                  // }}
                  alt="DeleteIcon"
                ></img>
              </div>
            </td>
          )}
          title="Delete"
          width={100}
          filterable={false}
        /> */}
      </Grid>
    </>
  );
}
