import { Window } from '@progress/kendo-react-dialogs';
import UpdateObjectHoldForm from './UpdateObjectHoldForm';
import { useContext, useEffect, useState } from 'react';
import UpdateObjectHoldFields from './UpdateObjectHoldFields.json';
import SelectableTable from '../../Table/SelectableTable';
import { searchHolds } from '../../../services/recordsManagementService';
import axios from 'axios';
import UserContext from '../../UserContext/UserContext';
import { RecordReferencesProvider } from '../../RecordReferences/RecordReferencesProvider';
import { convertDateToString } from '../../FormModules/FormDatepicker';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import './UpdateObjectHoldModal.scss';
import ErrorGrowl from '../../SuspenseQueue/ErrorGrowl';
import SuccessProcessGrowl from '../../SuspenseQueue/SuccessProcessGrowl';
import { GlobalLoader } from '../../../newcomponents/GlobalLoader/GlobalLoader';
import FullscreenIcon from '../../../fsa-style/img/svgs/fullScreenIcon.svg';
import crossIcon from '../../../fsa-style/img/crossIcon.svg';
export default function UpdateObjectHoldModal(props) {
  const { visible, onClose, recordIds } = props;
  const { userInfo } = useContext(UserContext);
  const [holdSearchResults, setHoldSearchResults] = useState([]);
  const [page, setPage] = useState(0);
  const [isErrorGrowlHidden, setIsErrorGrowlHidden] = useState(true);
  const [isSuccessGrowlHidden, setIsSuccessGrowlHidden] = useState(true);
  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [pageSize, setpageSize] = useState(15);

  const [isLoading, setIsLoading] = useState(false);
  const [expand, setExpand] = useState('DEFAULT');
  const expandHandler = () => {
    if (expand === 'DEFAULT') {
      setExpand('FULLSCREEN');
    } else {
      setExpand('DEFAULT');
    }
  };

  const onSearchSubmit = (search) => {
    const effectiveDate = convertDateToString(search.values.effectiveDate);
    const expirationDate = convertDateToString(search.values.expirationDate);

    const data = omitBy(
      {
        ...search.values,
        effectiveDate,
        expirationDate,
      },
      isEmpty
    );

    searchHolds(data)
      .then((r) => {
        setHoldSearchResults(r.data);
        setPage(0);
      })
      .catch((err) => {
        console.log(err);
        setErrMsg(err.message);
        setIsErrorGrowlHidden(false);

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
          setErrMsg('');
        }, 5000);
        // addNotification({ style: 'error', msg: `${err}` });
      });
  };

  const updateObjectsWithHolds = (holds) => {
    setIsLoading(true);
    let uniqueIdsArray = [];
    for (let i = 0; i < recordIds.length; i++) {
      let isAggObject = recordIds[i].hasOwnProperty('metadataIds');
      if (isAggObject === true) {
        let idArray = recordIds[i].metadataIds;
        for (let j = 0; j < idArray.length; j++) {
          uniqueIdsArray.push(idArray[j]);
        }
      } else {
        uniqueIdsArray = [];
        uniqueIdsArray = recordIds;
      }
    }
    const promises = holds.map(({ holdNumber }) => {
      const url = `/recordsmanagementapi/records-management/holds/add?holdNumber=${holdNumber}`;
      return axios.put(url, uniqueIdsArray, {
        headers: {
          userName: userInfo.name || '',
          usdaeauthid: userInfo.usdaeauthid || '',
          // Authorization: sessionStorage.getItem('userJwt') || '',
          'Content-Type': 'application/json',
        },
      });
    });

    Promise.all(promises)
      .then(() => {
        // addNotification({ style: 'success', msg: 'Object(s) updated with hold(s)' });
        setIsLoading(false);
        setSuccessMsg('Object(s) updated with hold(s)');
        setIsSuccessGrowlHidden(false);

        setTimeout(() => {
          setIsSuccessGrowlHidden(true);
          onClose();
        }, 3000);
      })
      .catch((err) => {
        // addNotification({ style: 'error', msg: `Cannot update object(s). ${err}` });
        setErrMsg(`Cannot update object(s). ${err}`);
        setIsLoading(false);
        setIsErrorGrowlHidden(false);

        setTimeout(() => {
          setIsErrorGrowlHidden(true);
        }, 5000);
        console.error(err);
      });
  };

  const onFormReset = () => {
    onSearchSubmit({
      values: {
        holdstatus: 'Active',
      },
    });
  };
  const onPageChange = ({ page }) => {
    const newPage = page.skip / page.take;
    setPage(newPage);
    setpageSize(page.take);
  };

  useEffect(() => {
    setExpand('DEFAULT');
    onSearchSubmit({
      values: {
        holdstatus: 'Active',
      },
    });
  }, [visible]);

  if (!visible) {
    return null;
  }

  return (
    <>
      <RecordReferencesProvider>
        <Window
          minimizeButton={() => null}
          maximizeButton={() => null}
          title={'Update Hold'}
          onClose={onClose}
          initialHeight={800}
          initialWidth={1200}
          style={{ padding: '1rem' }}
          className="updateObjHold"
          restoreButton={() => null}
          modal={true}
          draggable={false}
          stage={expand}
          closeButton={() => (
            <>
              <button aria-label="Expand Detail View Window" style={{ backgroundColor: 'Transparent', border: 'none' }} onClick={() => expandHandler()}>
                {/* <b>X</b> */}
                <img src={FullscreenIcon} alt="FullScreen icon" />
              </button>
              <button
                aria-label="Close Detail View Window"
                style={{ backgroundColor: 'Transparent', border: 'none' }}
                onClick={() => {
                  return onClose(), setExpand('DEFAULT');
                }}
              >
                {/* <b>X</b> */}
                <img src={crossIcon} alt="cross icon" />
              </button>
            </>
          )}
        >
          {isLoading && <GlobalLoader />}
          <div className={'row'}>
            <div className={'col-3 searchHold'}>
              <h3 style={{ marginTop: 0 }} className="holdHeading">
                Search Holds
              </h3>
              {/* <div className="fsa-divider"/> */}
              <UpdateObjectHoldForm onClose={onClose} onSubmit={onSearchSubmit} onFormReset={onFormReset} />
            </div>

            <div className={'col-9 holdTable'}>
              <h3 style={{ marginTop: 0 }} className="holdSearchHeading">
                Select a Hold
              </h3>
              <div className="fsa-divider" />
              <SelectableTable
                data={holdSearchResults.slice(page * pageSize, pageSize * page + pageSize)}
                fields={UpdateObjectHoldFields}
                total={holdSearchResults.length}
                onCancel={onClose}
                actionText={'Update'}
                onAction={updateObjectsWithHolds}
                onPageChange={onPageChange}
                page={page}
                pageSize={pageSize}
              />
            </div>
          </div>
          <ErrorGrowl isErrorGrowlHidden={isErrorGrowlHidden} setIsErrorGrowlHidden={setIsErrorGrowlHidden} message={errMsg} />
          <SuccessProcessGrowl isSuccessGrowlHidden={isSuccessGrowlHidden} setIsSuccessGrowlHidden={setIsSuccessGrowlHidden} message={successMsg} />
        </Window>
      </RecordReferencesProvider>
    </>
  );
}
